<template>
	<div>
		<BackButton :route="{ name: 'content-events', label: 'Events' }" />

		<ContentBuilder
			ref="builder"
			api-method="news"
			page-type="Event"
			parent-endpoint="events"
			edit-route="content-events-id"
			:url-prefix="urlPrefix"
			:restrict-url-prefix="true"
			:no-section-builder="true"
			:is-event="true"
			:include-keywords="true"
			:include-teaser-text="true"
			:include-details-text="true"
			:include-body-text="true"
			:hide-menu="true"
			:hide-section-icon="true"
			:hide-section-title="true"
			:hide-column-icon="true"
			:hide-column-title="true"
			:hide-publish="true"
		/>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

export default {
	components: {},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			urlPrefix: '/our-community/events/',
			page: {
				url: '',
				path: '',
				title: '',
				teaser_text: '',
				keywords: [],
				header_image: {
					id: 100,
					storage_path: '/assets/images/builder/header_default.jpg',
					title: 'test.jpg',
					size: 13200,
				},
				seo: {
					title: '',
					description: '',
					keywords: [],
					robots: '',
					canonical: '',
				},
				publish_date: '',
				event_date: '',
				event_start_time: '',
				event_end_time: '',
				details: '',
				location_virtual: false,
				location_existing: '',
				location_non_sc: '',
				registration_info: '',
				registration_action: '',
			},
		}
	},
	mounted() {
		//
	},
	methods: {
		//
	},
}
</script>
